import * as React from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/default"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Grid from "../../components/grid"
import Paragraph from "../../components/paragraph"
import Icon from "../../components/icon"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

function LiveIndex({ data }) {
  return (
    <Layout backdrop="live">
      <Seo
        title="Live"
        description="In unseren Live-Videos werden aktuelle Ereignisse aus unserem Museumsalltag zum Ausgangspunkt kurzer Rückblicke, Ausstellungsrundgänge oder Interviews mit Kooperationspartner*innen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Turm-TV",
                link: "/turm-tv",
              },
              {
                title: "Live",
                link: "/live",
              },
            ]}
          />
          <PageTitle>Hölderlinturm Live</PageTitle>
          <Paragraph dropcap={true}>
            In unseren Live-Videos werden aktuelle Ereignisse aus unserem
            Museumsalltag zum Ausgangspunkt kurzer Rückblicke,
            Ausstellungseinblicke oder Interviews mit Kooperationspartner*innen.
          </Paragraph>
        </Stack>
        <Grid as="ul" columns={[1, 1, 2, 3]} space={0}>
          {data.live.nodes.map((live) => (
            <Link to={live.nameSlug} key={live.meta.id}>
              <Box
                sx={{
                  overflow: "hidden",
                  borderStyle: "solid",
                  borderWidth: 4,
                  borderColor: "transparent",
                  ":hover .live-play-button": {
                    bg: "white",
                    color: "black",
                  },
                  ":hover": {
                    borderColor: "contrast",
                  },
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <GatsbyImage
                    image={live.meta.cover.childImageSharp.gatsbyImageData}
                    alt={`Titelbild Live ${live.name}`}
                  />
                  <Box
                    className="live-play-button"
                    sx={{
                      width: [12, 12, 16],
                      height: [12, 12, 16],
                      borderRadius: "100%",
                      bg: "black",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Icon icon="play" size={[6, 6, 8]} />
                  </Box>
                </Box>
              </Box>
            </Link>
          ))}
        </Grid>
      </Stack>
    </Layout>
  )
}

export default LiveIndex

export const query = graphql`
  {
    live: allLive(sort: { fields: meta___date, order: DESC }) {
      nodes {
        name
        nameSlug: gatsbyPath(filePath: "/live/{Live.name}")
        description
        meta {
          id
          date
          cover {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          src
        }
      }
    }
    ogImage: file(relativePath: { eq: "og_images/videos.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1200, height: 600, layout: FIXED)
      }
    }
  }
`
